const config: Object = {
  apiKey: "AIzaSyBhC7JToyXsdWx7R_xnbL5jhU72li-b8II",
  authDomain: "pechincha-ai.firebaseapp.com",
  databaseURL: "https://pechincha-ai.firebaseio.com",
  projectId: "pechincha-ai",
  storageBucket: "pechincha-ai.appspot.com",
  messagingSenderId: "835238985683",
  appId: "1:835238985683:web:5820b8547eb4e51d2c7926",
  measurementId: "G-45BD5X0J36"
}

export default config
