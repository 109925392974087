import firebase from "firebase"
import axios from "axios";
// import router from "@/router";

const baseURL = process.env.VUE_APP_CLABS_API;
console.log(baseURL)
const instance = axios.create({ baseURL });

instance.interceptors.request.use(
    async function(config: any) {
        const token = await firebase.auth().currentUser?.getIdToken(true)

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["x-firebase-token"] = true;
        }

        return config;
    },
    function(error: any) {
        return Promise.reject(error);
    }
);

// instance.interceptors.response.use(
//     (response: any) => response,
//     error => {
//         if (error.response.status === 401 || error.response.status === 403) {
//             localStorage.clear();
//             if (router.currentRoute.name !== 'login') {
//                 router.push("/");
//             }
//         }
//         return Promise.reject(error);
//     }
// );

export default instance;