export enum telegramNotificationSendInTypes {
  Chat = "CHAT",
  Channel = "CHANNEL"
}

export interface TelegramNotificationState {
  notifications: Array<TelegramNotification>
}

export interface TelegramNotification {
  id?: string
  image: string
  message: string
  send_in: string | telegramNotificationSendInTypes,
  status: string
  type: string
  created_at?: Date
}

export interface TelegramChannelState {
  channels: Array<TelegramChannel>
}

export interface TelegramChannel {
  id?: string
  active: boolean
  chat_id: string
  link: string
  status: string
  type: string
  title: string
  uid: string
  username: string
  created_at?: Date
  updated_at?: Date
}
