import { Module } from "vuex"
import { User } from "firebase"
import firebase from "firebase"

const authModule: Module<any, any> = {
  namespaced: true,
  state: {
    user: null,
    loading: true
  },

  mutations: {
    setUser(state: any, payload: User) {
      state.user = payload
    },
    setLoading(state: any, payload: Boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetchUser({ commit }) {
      firebase.auth().onAuthStateChanged(user => {
        // TODO buscar o usuário no Firestore
        commit("setUser", user)
        commit("setLoading", false)
      })
    },

    async login({ commit }, payload: any) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(user => {
          commit("setUser", user)
        })
        .catch((err: Error) => {
          this.dispatch("app/showCustomError", err.message)
        })
    },

    logout({ commit }) {
      commit("setUser", null)
      firebase.auth().signOut()
    }
  },

  getters: {
    getUser: (state: any) => state.user,
    getUid: (state: any) => state.user.uid,
    getLoading: (state: any) => state.loading
  }
}

export default authModule
