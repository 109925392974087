import { Module } from "vuex"
import axios from "@/config/axios.config";
import { CollectionStateV2, CollectionV2, Pagination, RootState } from "@/store/types";

const collectionsV2Module: Module<CollectionStateV2, RootState> = {
  namespaced: true,
  state: {
    sort: { active: 'desc', archived: 'asc', rank: 'asc' },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    collections: [],
    total: 0,
    loading: true
  },

  mutations: {
    setCollections(state: CollectionStateV2, payload: Array<CollectionV2>) {
      state.collections = payload
    },
    setPagination(state: CollectionStateV2, payload: Pagination) {
      state.pagination = payload
    },
    setTotal(state: CollectionStateV2, payload: number) {
      state.total = payload
    },
    setLoading(state: CollectionStateV2, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      commit("setPagination", payload)
      return axios.get(`/story-collection`, {
        params: { ...state.pagination, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setCollections", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    create({ dispatch, state }, { _id, ...payload }) {
      return axios.post(`/story-collection`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    update({ dispatch, state }, payload) {
      return axios.put(`/story-collection/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    archive({ dispatch, state }, payload) {
      return axios.put(`/story-collection/archive`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getCollections: function(state: CollectionStateV2): Array<CollectionV2> {
      return state.collections
    },
    getTotal: function(state: CollectionStateV2): number {
      return state.total
    },
    getLoading: function(state: CollectionStateV2): boolean {
      return state.loading
    }
  }
}

export default collectionsV2Module
