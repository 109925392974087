import { Module } from "vuex"
import axios from "@/config/axios.config";
import {
  Category,
  CategoryFilters,
  CategoryState,
  Pagination,
  RootState,
} from "@/store/types";

const path = '/category'

const categoriesModule: Module<CategoryState, RootState> = {
  namespaced: true,
  state: {
    filters: {},
    pagination: {
      page: 0,
      pageSize: 10,
    },
    sort: { name: 'asc' },
    categories: [],
    total: 0,
    loading: true,
  },

  mutations: {
    setCategories(state: CategoryState, payload: Array<Category>) {
      state.categories = payload
    },
    setFilters(state: CategoryState, payload: CategoryFilters) {
      state.filters = payload
    },
    setPagination(state: CategoryState, payload?: Pagination) {
      state.pagination = payload ?? state.pagination
    },
    setTotal(state: CategoryState, payload: number) {
      state.total = payload
    },
    setLoading(state: CategoryState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    async fetch({ commit, state }, pagination = {}) {
      commit("setLoading", true)
      commit("setPagination", pagination)
      return axios.get(path, {
        params: { ...state.filters, ...state.pagination, sort: state.sort, populate: 'affiliate_platform_id' }
      })
        .then(({ data }) => {
          commit("setCategories", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    async update({ dispatch }, payload) {
      return axios.put(`${path}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        });
    },

    archive({ dispatch }, payload) {
      return axios.delete(`${path}/${payload}`)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
    },
  },

  getters: {
    getCategories: function(state: CategoryState): Array<Category> {
      return state.categories
    },
    getTotal: function(state: CategoryState): number {
      return state.total
    },
    getLoading: function(state: CategoryState): boolean {
      return state.loading
    },
  }
}

export default categoriesModule
