export interface RootState {
  version: string
}

export interface Pagination  {
  page: number;
  pageSize: number
}

export interface ImageVersion {
  height: number;
  url: string;
  width: number;
}

export interface VideoVersion {
  height: number;
  url: string;
  width: number;
}

export interface Action {
  value: string;
  text: string;
  type: actionType;
}

export interface AssetDimensions {
  width: number
  height: number
}

export enum mediaType {
  Image = "IMAGE",
  Gif = "GIF",
  Video = "VIDEO"
}

export enum actionType {
  Link = "LINK",
  Email = "EMAIL",
  Pix = "PIX",
  Unset = "UNSET"
}
