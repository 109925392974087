import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: "*",
    redirect: "/home"
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Página Inicial",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/affiliate-platforms",
    name: "Plataformas de Afiliados",
    component: () => import("../views/AffiliatePlatforms/AffiliatePlatforms.vue")
  },
  {
    path: "/categories",
    name: "Categorias",
    component: () => import("../views/Categories/Categories.vue")
  },
  {
    path: "/mobile-apps",
    name: "Aplicativos",
    component: () => import("../views/MobileApps/MobileApps.vue")
  },
  {
    path: "/offers",
    name: "Offers",
    component: () => import("../views/Offers/Offers.vue")
  },
  {
    path: "/collections",
    name: "Coleções",
    component: () => import("../views/CollectionsV2/CollectionsV2.vue")
  },
  {
    path: "/stories",
    name: "Stories",
    component: () => import("../views/StoriesV2/StoriesV2.vue")
  },
  {
    path: "/highlights",
    name: "Destaques",
    component: () => import("../views/Highlights/Highlights.vue")
  },
  {
    path: "/magalu-codes",
    name: "Códigos da Magalu",
    component: () => import("../views/Codes/Codes.vue")
  },
  {
    path: "/lightning-offers",
    name: "Ofertas Relâmpago",
    component: () => import("../views/LightningOffers/LightningOffers.vue")
  },
  {
    path: "/telegram-channels",
    name: "Canais do Telegram",
    component: () => import("../views/TelegramChannels/TelegramChannels.vue")
  },
  {
    path: "/telegram-notifications",
    name: "Notificações do Telegram",
    component: () => import("../views/TelegramNotifications/TelegramNotifications.vue")
  },
  {
    path: "/elise-statistics",
    name: "Elise P. - Estatísticas",
    component: () => import("../views/EliseStatistics/EliseStatistics.vue")
  },
  {
    path: "/images",
    name: "ImagesPage",
    component: () => import("../views/Images/ImagesPage.vue")
  },
  {
    path: "/offer-images",
    name: "OfferImagesPage",
    component: () => import("../views/OfferImages/OfferImagesPage.vue")
  },
  {
    path: "/banners",
    name: "BannersPage",
    component: () => import("../views/Banners/BannersPage.vue")
  },
  {
    path: "/domains",
    name: "DomainsPage",
    component: () => import("../views/Domains/Domains.vue")
  },
  {
    path: "/feed-banners",
    name: "FeedBannersPage",
    component: () => import("../views/FeedBanners/FeedBannersPage.vue")
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (!localStorage.getItem('token')) {
//       next('/login')
//       return
//     }
//   }
//   next()
// })

export default router
