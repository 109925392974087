



















































































import Vue from "vue";
import { mapGetters } from "vuex";
import Loading from "./views/LoadingPage.vue";
import Login from "./views/Login.vue";

export default Vue.extend({
  name: "App",

  components: {
    Loading,
    Login
  },

  data: () => ({
    drawer: false,
    group: null,
    nodeEnv: process.env.VUE_APP_NODE_ENV,
    routes: [
      { name: 'Página Inicial', icon: "mdi-home", link: '/home', roles: [] },
      { name: 'Aplicativos', icon: "mdi-apps-box", link: '/mobile-apps', roles: [] },
      { name: 'Plataformas de Afiliados', icon: "mdi-handshake", link: '/affiliate-platforms', roles: [] },
      { name: 'Categorias', icon: "mdi-shape", link: '/categories', roles: [] },
      { name: 'Banners', icon: "mdi-image-frame", link: '/banners', roles: [] },
      { name: 'Feed Banners', icon: "mdi-timeline", link: '/feed-banners', roles: [] },
      { name: 'Stories', icon: "mdi-pillar", link: '/stories', roles: [] },
      { name: 'Coleções dos Stories', icon: "mdi-bookmark-box-multiple-outline", link: '/collections', roles: [] },
      { name: 'Imagens de Ofertas', icon: "mdi-image-search-outline", link: '/offer-images', roles: [] },
      { name: 'Imagens', icon: "mdi-image-text", link: '/images', roles: [] },
      { name: 'Destaques', icon: "mdi-marker", link: '/highlights', roles: [] },
      { name: 'Domínios (Whitelist)', icon: "mdi-domain", link: '/domains', roles: [] },
      { name: 'Códigos da Magalu', icon: "mdi-barcode", link: '/magalu-codes', roles: [] },
      { name: 'Ofertas Relâmpago', icon: "mdi-flash", link: '/lightning-offers', roles: [] },

      { name: 'Ofertas (Dep)', icon: "mdi-shopping", link: '/offers', roles: [] },
      { name: 'Canais do Telegram (Dep)', icon: "mdi-send-circle", link: '/telegram-channels', roles: [] },
      { name: 'Elise P. - Notificações (Dep)', icon: "mdi-message-fast", link: '/telegram-notifications', roles: [] },
    ]
  }),

  computed: {
    ...mapGetters({
      alerts: "app/getAlerts",
      loading: "auth/getLoading",
      user: "auth/getUser"
    })
  },

  methods: {
    goTo(path: string) {
      this.$router.push(path)
    }
  },
});
