import { Module } from "vuex"
import firebase from "firebase"
import { RootState, TelegramChannel, TelegramChannelState } from "@/store/types";

const FIREBASE_COLL = process.env.VUE_APP_TELEGRAM_CHANNELS

const telegramChannelsModule: Module<TelegramChannelState, RootState> = {
  namespaced: true,
  state: {
    channels: []
  },

  mutations: {
    setChannels(state: TelegramChannelState, payload: Array<TelegramChannel>) {
      state.channels = payload
    }
  },

  actions: {
    fetch({ commit }) {
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .get()
        .then(data => {
          const channels: Array<any> = []
          data.forEach(channel => {
            channels.push(channel.data())
          })
          commit("setChannels", channels)
        })
    },

    create({ dispatch }, payload: TelegramChannel) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      payload["id"] = colRef.doc().id

      colRef
        .doc(payload.id)
        .set(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    update({ dispatch }, payload: TelegramChannel) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .doc(payload.id)
        .update(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    delete({ dispatch }, payload: TelegramChannel) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .doc(payload.id)
        .delete()
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    }
  },

  getters: {
    getChannels: function(state: TelegramChannelState): Array<TelegramChannel> {
      return state.channels
    }
  }
}

export default telegramChannelsModule
