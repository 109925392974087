import { Module } from "vuex"

const appModule: Module<any, any> = {
  namespaced: true,
  state: {
    alerts: []
  },

  mutations: {
    pushAlert(state: any, payload: any) {
      state.alerts.push(payload)
      setTimeout(() => {
        state.alerts = state.alerts.filter((val: any) => val !== payload)
      }, 1500)
    }
  },

  actions: {
    showIsLoading({ commit }) {
      commit("pushAlert", {
        type: "loading",
        message: "Carregando, aguarde...",
        color: "blue-grey"
      })
    },
    showIsSuccess({ commit }, message: string) {
      commit("pushAlert", {
        type: "success",
        message: message ?? "Operação bem sucedida!"
      })
    },
    showIsError({ commit }) {
      commit("pushAlert", {
        type: "error",
        message: "Ocorreu um erro!"
      })
    },
    showCustomError({ commit }, message: string) {
      commit("pushAlert", {
        type: "error",
        message: message
      })
    }
  },

  getters: {
    getAlerts: state => state.alerts
  }
}

export default appModule
