import { Pagination } from "@/store/types/utils";

interface Position {
  dx: number;
  dy: number;
}

export enum ItemType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  BG_COLOR = 'BG_COLOR',
  BG_IMAGE = 'BG_IMAGE'
}

interface BaseItem {
  _id?: string;
  position: Position;
  type: ItemType;
  content: string | number;
  locked: boolean;
  reference: string;
  height: number;
  width: number;
  rotate: number;
}

interface ImageItem extends BaseItem {
  type: ItemType.IMAGE;
}

interface TextItem extends BaseItem {
  type: ItemType.TEXT;
  font_size: number;
  font_color: number;
  text_align: string;
  font_weight: string;
  text_decoration: string;
  font_style: string;
  font_family: string;
}

interface ColorItem extends BaseItem {
  type: ItemType.BG_COLOR;
  content: number;
}

export type Item = ImageItem | TextItem | ColorItem;

export interface Banner {
  _id?: string;
  name: string;
  user_generated?: boolean;
  editor_version: string;
  height: number;
  width: number;
  ratio_height: number;
  ratio_width: number;
  tags: string[];
  background?: number;
  active: boolean;
  created_at: string;
  updated_at: string;
  items: Item[];
}

export interface BannersFilters {
  name?: string;
  tags?: string;
  editor_version?: string;
  active?: boolean;
}

export interface BannersState {
  sort: object;
  filters: BannersFilters;
  pagination: Pagination;
  banners: Banner[];
  banner: Banner | {};
  total: number;
  loading: boolean;
}
