import { render, staticRenderFns } from "./LoadingPage.vue?vue&type=template&id=6305ae3a&scoped=true&"
import script from "./LoadingPage.vue?vue&type=script&lang=js&"
export * from "./LoadingPage.vue?vue&type=script&lang=js&"
import style0 from "./LoadingPage.vue?vue&type=style&index=0&id=6305ae3a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6305ae3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
