import { Module } from "vuex"
import axios from "@/config/axios.config";
import { Domain, DomainState, RootState } from "@/store/types";

const domainsPath = '/domain'

const domainsModule: Module<DomainState, RootState> = {
  namespaced: true,
  state: {
    sort: { created_at: 'desc' },
    domains: [],
    total: 0,
    loading: true,
  },

  mutations: {
    setDomains(state: DomainState, payload: Array<Domain>) {
      state.domains = payload
    },
    setTotal(state: DomainState, payload: number) {
      state.total = payload
    },
    setLoading(state: DomainState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      return axios.get(domainsPath, {
        params: { ...payload, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setDomains", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    create({ dispatch }, { _id, ...payload }) {
      return axios.post(domainsPath, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    update({ dispatch }, payload) {
      return axios.put(`${domainsPath}/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    delete({ dispatch }, payload) {
      return axios.delete(domainsPath, { params: payload })
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getDomains: function(state: DomainState): Array<Domain> {
      return state.domains
    },
    getTotal: function(state: DomainState): number {
      return state.total
    },
    getLoading: function(state: DomainState): boolean {
      return state.loading
    },
  }
}

export default domainsModule
