import { Module } from "vuex"
import axios from "@/config/axios.config";
import { Image, ImagesState, Pagination, RootState } from "@/store/types";

const imagesModule: Module<ImagesState, RootState> = {
  namespaced: true,
  state: {
    sort: { created_at: 'desc' },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    images: [],
    total: 0,
    loading: false,
  },

  mutations: {
    setImages(state: ImagesState, payload: Array<Image>) {
      state.images = payload
    },
    setPagination(state: ImagesState, payload: Pagination) {
      state.pagination = payload
    },
    setTotal(state: ImagesState, payload: number) {
      state.total = payload
    },
    setLoading(state: ImagesState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      commit("setPagination", payload)
      return axios.get(`/image`, {
        params: { ...state.pagination, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setImages", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    create({ dispatch, state }, { _id, ...payload }) {
      return axios.post(`/image`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    update({ dispatch, state }, payload) {
      return axios.put(`/image/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getImages: function(state: ImagesState): Array<Image> {
      return state.images
    },
    getTotal: function(state: ImagesState): number {
      return state.total
    },
    getLoading: function(state: ImagesState): boolean {
      return state.loading
    }
  }
}

export default imagesModule
