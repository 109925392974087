import { Module } from "vuex"
import firebase from "firebase"
import { Codes, CodesState, RootState } from "@/store/types";

const FIREBASE_COLL = process.env.VUE_APP_MAGALU_CODES

const codesModule: Module<CodesState, RootState> = {
  namespaced: true,
  state: {
    codes: { id: '', codes: '' }
  },

  mutations: {
    setCodes(state: CodesState, payload: Codes) {
      state.codes = payload
    }
  },

  actions: {
    fetch({ commit }) {
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .get()
        .then(data => {
          const [val] = data.docs
          commit("setCodes", { id: val.id, ...val.data() })
        })
    },

    update({ dispatch }, payload: Codes) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .doc(payload.id)
        .update(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },
  },

  getters: {
    getCodes: function(state: CodesState): Codes | null {
      return state.codes
    }
  }
}

export default codesModule
