import { Module } from "vuex"
import axios from "@/config/axios.config";
import { Highlight, HighlightState, Pagination, RootState} from "@/store/types";

const highlightsModule: Module<HighlightState, RootState> = {
    namespaced: true,
    state: {
        sort: { active: 'desc', created_at: 'desc' },
        pagination: {
            page: 0,
            pageSize: 10,
        },
        highlights: [],
        total: 0,
        loading: false,
    },

    mutations: {
        setHighlights(state: HighlightState, payload: Array<Highlight>) {
            state.highlights = payload
        },
        setPagination(state: HighlightState, payload: Pagination) {
            state.pagination = payload
        },
        setTotal(state: HighlightState, payload: number) {
            state.total = payload
        },
        setLoading(state: HighlightState, payload: boolean) {
            state.loading = payload
        }
    },

    actions: {
        fetch({ commit, state }, payload) {
            commit("setLoading", true)
            commit("setPagination", payload)
            return axios.get(`/highlight`, {
                  params: { ...state.pagination, sort: state.sort }
              })
                .then(({ data }) => {
                    commit("setHighlights", data.items)
                    commit("setTotal", data.total)
                })
                .catch(console.log)
              .finally(() => commit("setLoading", false));
        },

        create({ dispatch, state }, { _id, ...payload }) {
            axios.post(`/highlight`, payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetch", state.pagination)
                })
                .catch(() => this.dispatch("app/showIsError"));
        },

        update({ dispatch, state }, payload) {
            axios.put(`/highlight/${payload._id}`, payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetch", state.pagination)
                })
                .catch(() => this.dispatch("app/showIsError"));
        },

        archive({ dispatch, state }, payload) {
            axios.put(`/highlight/archive`, payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetch", state.pagination)
                })
                .catch(() => this.dispatch("app/showIsError"));
        },
    },

    getters: {
        getHighlights: function(state: HighlightState): Array<Highlight> {
            return state.highlights
        },
        getTotal: function(state: HighlightState): number {
            return state.total
        },
        getLoading: function(state: HighlightState): boolean {
            return state.loading
        }
    }
}

export default highlightsModule
