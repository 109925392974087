import Vue from "vue"
import Vuex from "vuex"

import config from "../config/firebase.config"
import * as Firebase from "firebase/app"
Firebase.initializeApp(config)

import app from "@/store/modules/app"
import auth from "@/store/modules/auth"
import offer from "@/store/modules/offers"
import uploader from "@/store/modules/uploader";
import telegramChannels from "@/store/modules/telegram-channels";
import telegramNotifications from "@/store/modules/telegram-notifications";
import codes from "@/store/modules/codes";
import storiesV2Module from "@/store/modules/stories_v2";
import collectionsV2Module from "@/store/modules/collections";
import highlightsModule from "@/store/modules/highlights";
import mobileAppsModule from "@/store/modules/mobile-apps";
import imagesModule from "@/store/modules/images";
import bannersModule from "@/store/modules/banners";
import offerImagesModule from "@/store/modules/offer-images";
import domainsModule from "@/store/modules/domains";
import affiliatePlatformsModule from "@/store/modules/affiliate-platforms";
import feedBannersModule from "@/store/modules/feed-banners";
import categoriesModule from "@/store/modules/categories";

import { RootState } from "@/store/types";

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  modules: {
    app,
    auth,
    offer,
    uploader,
    telegramChannels,
    telegramNotifications,
    codes,
    storiesV2Module,
    collectionsV2Module,
    highlightsModule,
    mobileAppsModule,
    imagesModule,
    bannersModule,
    offerImagesModule,
    domainsModule,
    affiliatePlatformsModule,
    feedBannersModule,
    categoriesModule,
  }
})

store.dispatch("auth/fetchUser")

export default store
