import { Module } from "vuex"
import axios from "@/config/axios.config";
import {
  AffiliatePlatform,
  AffiliatePlatformFilters,
  AffiliatePlatformState,
  Pagination,
  RootState,
} from "@/store/types";

const path = '/affiliate-platforms'

const affiliatePlatformsModule: Module<AffiliatePlatformState, RootState> = {
  namespaced: true,
  state: {
    filters: {},
    pagination: {
      page: 0,
      pageSize: 10,
    },
    sort: { name: 'asc' },
    platforms: [],
    total: 0,
    loading: true,
  },

  mutations: {
    setPlatforms(state: AffiliatePlatformState, payload: Array<AffiliatePlatform>) {
      state.platforms = payload
    },
    setFilters(state: AffiliatePlatformState, payload: AffiliatePlatformFilters) {
      state.filters = payload
    },
    setPagination(state: AffiliatePlatformState, payload?: Pagination) {
      state.pagination = payload ?? state.pagination
    },
    setTotal(state: AffiliatePlatformState, payload: number) {
      state.total = payload
    },
    setLoading(state: AffiliatePlatformState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, pagination = {}) {
      commit("setLoading", true)
      commit("setPagination", pagination)
      return axios.get(path, {
        params: { ...state.filters, ...state.pagination, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setPlatforms", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    create({ dispatch }, { _id, ...payload }) {
      return axios.post(path, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        });
    },

    update({ dispatch }, payload) {
      return axios.put(`${path}/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        });
    },

    archive({ dispatch }, payload) {
      return axios.delete(`${path}/${payload}`)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
    },
  },

  getters: {
    getPlatforms: function(state: AffiliatePlatformState): Array<AffiliatePlatform> {
      return state.platforms
    },
    getTotal: function(state: AffiliatePlatformState): number {
      return state.total
    },
    getLoading: function(state: AffiliatePlatformState): boolean {
      return state.loading
    },
  }
}

export default affiliatePlatformsModule
