import { Module } from "vuex"
import firebase from "firebase"
import {ImageUpload, RootState} from "@/store/types";

const FIREBASE_BUCKET = process.env.VUE_APP_STORAGE_BUCKET

const uploaderModule: Module<any, RootState> = {
  namespaced: true,
  state: {},

  mutations: {},

  actions: {
    upload({ dispatch }, payload: ImageUpload) {
      this.dispatch("app/showIsLoading")
      const storage = firebase.app().storage(FIREBASE_BUCKET);
      const folder = storage.ref(payload.folder)

      return folder
        .child(payload.filename)
        .put(payload.file, payload.metadata)
        .then(async (snapshot) => {
          const url = await snapshot.ref.getDownloadURL()
          this.dispatch("app/showIsSuccess")
          return url
        })
        .catch(() => this.dispatch("app/showIsError"))
    },
  },

  getters: {}
}

export default uploaderModule
