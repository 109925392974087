



















import Vue from "vue";

export default Vue.extend({
  name: "Login" as string,

  data() {
    return {
      user: { email: "", password: "" } as Object,
      loading: false as Boolean
    };
  },

  methods: {
    async doLogin(): Promise<void> {
      this.loading = true;

      await this.$store.dispatch("auth/login", this.user);

      this.loading = false;
    }
  }
});
