import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, FeedBanner, FeedBannersState, Pagination, FeedBannersFilters, SharingStatus } from "../types"

const feedBannersModule: Module<FeedBannersState, RootState> = {
  namespaced: true,
  state: {
    sort: { created_at: 'desc' },
    filters: {
      sharing_status: SharingStatus.PENDING_REVIEW
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    banners: [],
    banner: undefined,
    total: 0,
    loading: true
  },

  mutations: {
    setBanners(state: FeedBannersState, payload: Array<FeedBanner>) {
      state.banners = payload
    },
    setFilters(state: FeedBannersState, payload: FeedBannersFilters) {
      state.filters = payload
    },
    setBanner(state: FeedBannersState, payload: FeedBanner | undefined) {
      state.banner = payload
    },
    setPagination(state: FeedBannersState, payload: Pagination) {
      state.pagination = payload
    },
    setTotal(state: FeedBannersState, payload: number) {
      state.total = payload
    },
    setLoading(state: FeedBannersState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      commit("setPagination", payload)
      commit("setBanner", undefined)
      return axios.get(`/feed-banners`, {
        params: { ...state.pagination, ...state.filters, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setBanners", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    update({ dispatch, state }, payload) {
      return axios.put(`/feed-banners`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getBanners: function(state: FeedBannersState): Array<FeedBanner> {
      return state.banners
    },
    getBanner: function(state: FeedBannersState): FeedBanner | undefined {
      return state.banner
    },
    getTotal: function(state: FeedBannersState): number {
      return state.total
    },
    getLoading: function(state: FeedBannersState): boolean {
      return state.loading
    }
  }
}

export default feedBannersModule
