import { Pagination } from "@/store/types/utils";

export enum SharingStatus {
  UNSET = 'UNSET',
  PENDING_REVIEW = 'PENDING_REVIEW',
  REVIEWING = 'REVIEWING',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED',
  BANNED = 'BANNED',
  APPROVED = 'APPROVED'
}

export interface FeedBanner {
  _id: string
  account_id: string
  banned: boolean
  banned_at: any
  created_at: string
  name: string
  reason_for_ban: string
  reason_for_rejection: string
  reports: number
  shared: boolean
  sharing_status: SharingStatus
  tags: string[]
  template: string
  updated_at: string
}

export interface FeedBannersFilters {
  sharing_status?: SharingStatus;
}

export interface FeedBannersState {
  sort: object;
  filters: FeedBannersFilters;
  pagination: Pagination;
  banners: FeedBanner[];
  banner?: FeedBanner;
  total: number;
  loading: boolean;
}
