import { Module } from "vuex"
import axios from "@/config/axios.config";
import { Pagination, RootState, StoriesStateV2, StoryV2} from "@/store/types";

const storiesV2Module: Module<StoriesStateV2, RootState> = {
  namespaced: true,
  state: {
    sort: { active: 'desc', expiring_at: 'asc' },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    stories: [],
    total: 0,
    loading: false,
  },
  
  mutations: {
    setStories(state: StoriesStateV2, payload: Array<StoryV2>) {
      state.stories = payload
    },
    setPagination(state: StoriesStateV2, payload: Pagination) {
      state.pagination = payload
    },
    setTotal(state: StoriesStateV2, payload: number) {
      state.total = payload
    },
    setLoading(state: StoriesStateV2, payload: boolean) {
      state.loading = payload
    }
  },
  
  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      commit("setPagination", payload)
      return axios.get(`/story`, {
          params: { ...state.pagination, sort: state.sort }
        })
        .then(({ data }) => {
          console.log(data)
          commit("setStories", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },
    
    create({ dispatch, state }, { _id, ...payload }) {
      return axios.post(`/story`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
    
    update({ dispatch, state }, payload) {
      return axios.put(`/story/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
    
    deactivate({ dispatch, state }, payload) {
      return axios.put(`/story/deactivate`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },
  
  getters: {
    getStories: function(state: StoriesStateV2): Array<StoryV2> {
      return state.stories
    },
    getTotal: function(state: StoriesStateV2): number {
      return state.total
    },
    getLoading: function(state: StoriesStateV2): boolean {
      return state.loading
    }
  }
}

export default storiesV2Module
