import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, Banner, BannersState, Pagination, BannersFilters } from "../types"

const bannersModule: Module<BannersState, RootState> = {
  namespaced: true,
  state: {
    sort: { created_at: 'desc' },
    filters: {},
    pagination: {
      page: 0,
      pageSize: 10,
    },
    banners: [],
    banner: {},
    total: 0,
    loading: true
  },

  mutations: {
    setBanners(state: BannersState, payload: Array<Banner>) {
      state.banners = payload
    },
    setFilters(state: BannersState, payload: BannersFilters) {
      state.filters = payload
    },
    setBanner(state: BannersState, payload: Banner) {
      state.banner = payload
    },
    setPagination(state: BannersState, payload: Pagination) {
      state.pagination = payload
    },
    setTotal(state: BannersState, payload: number) {
      state.total = payload
    },
    setLoading(state: BannersState, payload: boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetch({ commit, state }, payload = {}) {
      commit("setLoading", true)
      commit("setPagination", payload)
      return axios.get(`/banner/new-find`, {
        params: { ...state.pagination, ...state.filters, sort: state.sort }
      })
        .then(({ data }) => {
          commit("setBanners", data.items)
          commit("setTotal", data.total)
        })
        .catch(console.log)
        .finally(() => commit("setLoading", false));
    },

    create({ dispatch, commit, state }, { _id, ...payload }) {
      return axios.post(`/banner`, payload)
        .then(({ data }) => {
          commit('setBanner', data)
          dispatch("fetch", state.pagination)
          this.dispatch("app/showIsSuccess")
        })
        .catch(() => this.dispatch("app/showIsError"));
    },

    update({ dispatch, state }, payload) {
      return axios.put(`/banner/${payload._id}`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch", state.pagination)
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getBanners: function(state: BannersState): Array<Banner> {
      return state.banners
    },
    getBanner: function(state: BannersState): Banner | {} {
      return state.banner
    },
    getTotal: function(state: BannersState): number {
      return state.total
    },
    getLoading: function(state: BannersState): boolean {
      return state.loading
    }
  }
}

export default bannersModule
