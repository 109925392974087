<template>
  <div class="loading">
    <v-progress-circular :size="100" :width="3" color="purple" indeterminate></v-progress-circular>
    <br>
    <h2>Carregando Dashboard</h2>
  </div>
</template>

<script>
export default {
  name: "LoadingPage"
};
</script>

<style lang="sass" scoped>
.loading
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
</style>
