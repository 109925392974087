import { Module } from "vuex"
import axios from "@/config/axios.config";
import { MobileApp, MobileAppState, RootState } from "@/store/types";

const mobileAppsModule: Module<MobileAppState, RootState> = {
    namespaced: true,
    state: {
        apps: [],
        total: 0
    },

    mutations: {
        setApps(state: MobileAppState, payload: Array<MobileApp>) {
            state.apps = payload
        },
        setTotal(state: MobileAppState, payload: number) {
            state.total = payload
        }
    },

    actions: {
        fetch({ commit }, payload = {}) {
            return axios.get(`/app`, { params: payload })
                .then(({ data }) => {
                    commit("setApps", data.items);
                    commit("setTotal", data.total)
                })
                .catch(console.log);
        },

        create({ dispatch }, { _id, ...payload }) {
            return axios.post(`/app`, payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetch")
                })
                .catch(() => this.dispatch("app/showIsError"));
        },

        update({ dispatch }, payload) {
            return axios.put(`/app/${payload._id}`, payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetch")
                })
                .catch(() => this.dispatch("app/showIsError"));
        }
    },

    getters: {
        getApps: function(state: MobileAppState): Array<MobileApp> {
            return state.apps
        },
        getTotal: function(state: MobileAppState): number {
            return state.total
        }
    }
}

export default mobileAppsModule
